<template>
    <div class="case-page">
        <page-title :title="'案件详细信息'">案件详细信息</page-title>
        <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="案件基本信息" name="first">
                    <Detail ref="detail"></Detail>
                </el-tab-pane>
                <el-tab-pane label="案件详细信息" name="second">
                    <PartsList ref="PartsList"></PartsList>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Detail from './detail.vue';
import PartsList from './partslist.vue';
export default {
    name:"DetailIndex",
    components:{
        Detail,PartsList
    },
    data(){
        return{
            activeName:"first"
        }
    },
    methods:{
        /**tab点击切换事件,切换后调用子组件的页面init方法。 */
        handleClick(e){
            const that  =this;
            if(e.name === 'first'){
                that.$refs.detail.initPage();
            }else if(e.name === 'second'){
                that.$refs.PartsList.initPage();
            }
        }
    }
}
</script>
<style lang="less" scoped>
.case-page{
    /deep/ .el-tabs__item{
        font-size: 16px;
        &:hover{
            color: #0ba882;
        }
    }
    /deep/ .el-tabs__item.is-active{
        color: #0ba882;
        font-weight: bold;
    }
    /deep/ .el-tabs__active-bar{
        background-color: #0ba882;
    }
}
</style>