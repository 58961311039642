<template tips="配件列表信息">
    <div class="page-container">
        <div class="table-area">
            <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                <div style="display: flex;align-items: center;">
                    <span style="font-size: 16px;width: 150px;">维修商报价：</span>
                    <el-input :value="gatherData[0].costTotal" size="small" disabled></el-input>
                </div>
                <div>
                    <!-- <el-button type="primary" size="small">新增</el-button> -->
                    <el-button type="primary" size="small" @click="genWordDown">生成并下载清单</el-button>
                </div>
            </div>
            <el-table :data="tableData">
                <el-table-column label="序号" align="center" width="50" type="index"></el-table-column>
                <el-table-column label="受损客户信息" align="center">
                    <el-table-column label="客户" prop="cusName" width="80" align="center"></el-table-column>
                    <el-table-column label="电话" prop="cusTel" max-width="110"></el-table-column>
                    <el-table-column label="受损电器" prop="eleName"></el-table-column>
                    <!-- <el-table-column label="受损电器" prop="eleSpecsType">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="selLoseEle(scope.row.eleId,scope.row.eleSpecsType)">
                                {{ scope.row.eleSpecsType?scope.row.eleSpecsType:'选择' }}
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table-column>
                <el-table-column label="修理及维修信息" align="center">
                    <!-- <el-table-column label="照片">
                        <el-button size="mini" type="text">查看照片</el-button>
                    </el-table-column> -->
                    <el-table-column label="受损配件">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="selParts(scope.row.eleId,scope.row.cusId,scope.row.partsName)">{{scope.row.partsName?scope.row.partsName:'选择配件'}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="配件型号">
                        <template slot-scope="scope">
                            <el-input size="mini" style="width:100%;" v-model="scope.row.partsModel"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="数量" width="80" align="center" prop="partsCount">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.partsCount" size="mini" :controls="false" style="width: 100%;" @blur="countBlur(scope.row)"></el-input-number>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="修理及更换信息" align="center">
                    <el-table-column label="维修/变更" prop="repairType" header-align="center">
                        <template slot-scope="scope">
                            <el-select placeholder="请选择" size="mini" v-model="scope.row.repairType">
                                <el-option key="维修" label="维修" value="维修"></el-option>
                                <el-option key="更换" label="更换" value="更换"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="配件费" prop="partsPrice" header-align="center">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.partsPrice" size="mini" :controls="false" style="width: 100%;" @blur="costBlur(scope.row)"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="维修费" prop="partsRepairCost" header-align="center">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.partsRepairCost" size="mini" :controls="false" style="width: 100%;" @blur="costBlur(scope.row)"></el-input-number>
                        </template>
                    </el-table-column>
                    <!-- 2023-03-31 将checkPrice作为小计字段暂时处理 -->
                    <el-table-column label="小计" prop="checkPrice">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.checkPrice" size="mini" :controls="false" style="width: 100%;" disabled></el-input-number>
                        </template>
                    </el-table-column>
                </el-table-column>
                <!-- <el-table-column label="核定金额" align="center" prop="checkPrice">
                    <template slot-scope="scope">
                            <el-input-number v-model="scope.row.checkPrice" size="mini" :controls="false" style="width: 100%;"></el-input-number>
                        </template>
                </el-table-column>
                <el-table-column label="复核金额" align="center" prop="reviewPrice">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.reviewPrice" size="mini" :controls="false" style="width: 100%;"></el-input-number>
                    </template>
                </el-table-column> -->
                <el-table-column label="复核情况" align="center" prop="reviewInfo">
                    <template slot-scope="scope">
                        <el-input size="mini" style="width:100%;" v-model="scope.row.reviewInfo"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="备注" align="center" prop="remark">
                    <template slot-scope="scope">
                        <el-input size="mini" style="width:100%;" v-model="scope.row.remark"></el-input>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="维修员" align="center" prop="repairPeo"></el-table-column> -->
            </el-table>
            <el-table border :show-header="false" :data="gatherData"  :span-method="arraySpanMethod">
                <el-table-column label="名目" align="center" width="50" prop="name"></el-table-column>
                <el-table-column label="数量汇总" prop="partsTotal" width="80" align="center">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.partsTotal" size="mini" :controls="false" style="width: 100%;" disabled></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="类别"></el-table-column>
                <el-table-column label="费用合计" prop="costTotal">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.costTotal" size="mini" :controls="false" style="width: 100%;" disabled></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="核定金额" max-width="110" prop="checkCost">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.checkCost" size="mini" :controls="false" style="width: 100%;"></el-input-number>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="复核金额" prop="reviewCost">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.reviewCost" size="mini" :controls="false" style="width: 100%;"></el-input-number>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column label=""></el-table-column> -->
                <el-table-column label=""></el-table-column>
                <!-- <el-table-column label=""></el-table-column> -->
                <el-table-column label="" width="80" align="center"></el-table-column>
                <el-table-column label=""></el-table-column>
                <el-table-column label=""></el-table-column>
                <el-table-column label=""></el-table-column>
                <el-table-column label="" align="center"></el-table-column>
                <el-table-column label="" align="center"></el-table-column>
                <el-table-column label="" align="center"></el-table-column>
                <!-- <el-table-column label="" align="center"></el-table-column> -->
            </el-table>
            <div style="margin-top:20px;text-align: right;">
                <el-button type="primary" size="small" @click="submitAll">保存</el-button>
                <el-button type="info" size="small">返回</el-button>
            </div>
        </div>
        <el-dialog title="选择受损电器" :visible.sync="eleDialogShow" custom-class="my-dialog-style" style="padding-top:10px;">
            <div style="min-height:420px;max-height: 600px;">
                <div style="position: relative;">
                    <p style="margin:0px;padding-bottom:10px;font-weight: bold;border-bottom: 1px solid #cccccc;">规格信息</p>
                    <div style="position: absolute;top: 0px; right: 0px;">
                        <el-input placeholder="请输入规格" v-model="inputSpecsType.eleSpecs" style="width: 240px;" size="mini"></el-input>
                        <el-button type="primary" style="margin-left: 20px;padding: 6px 12px;" size="mini" @click="addSpecsType()">新增</el-button>
                    </div>
                    <div  v-if="eleSelList.eleSpecs">
                        <el-radio-group style="line-height:25px;padding-top: 10px;" v-model="eleSelObj.eleSpecs" @change="$forceUpdate()">
                            <el-radio :label="item" v-for="item,index in eleSelList.eleSpecs" :key="index">{{item}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div style="position: relative;margin-top: 10px;">
                    <p style="margin:0px;padding-bottom:10px;font-weight: bold;border-bottom: 1px solid #cccccc;">型号信息</p>
                    <div style="position: absolute;top: 0px; right: 0px;">
                        <el-input placeholder="请输入型号" v-model="inputSpecsType.eleType" style="width: 240px;" size="mini"></el-input>
                        <el-button type="primary" style="margin-left: 20px;padding: 6px 12px;" size="mini" @click="addSpecsType()">新增</el-button>
                    </div>
                    <div  v-if="eleSelList.eleType">
                        <el-radio-group style="line-height:25px;padding-top: 10px;" v-model="eleSelObj.eleType" @change="$forceUpdate()">
                            <el-radio :label="item" v-for="item,index in eleSelList.eleType" :key="index">{{item}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="eleDialogShow = false" size="small">取 消</el-button>
                <el-button type="primary" @click="confirmEleSel" size="small">确定选择</el-button>
            </div>
        </el-dialog>
        <el-dialog title="选择配件" :visible.sync="partDialogShow" custom-class="my-dialog-style" style="padding-top:10px;">
            <div style="min-height:420px;max-height: 600px;">
                <div style="position: relative;">
                    <p style="margin:0px;padding-bottom:10px;font-weight: bold;border-bottom: 1px solid #cccccc;">配件类型</p>
                    <div style="position: absolute;top: 0px; right: 0px;">
                        <el-input placeholder="请输入配件类型" v-model="inputSpecsType.partType" style="width: 240px;" size="mini"></el-input>
                        <el-button type="primary" style="margin-left: 20px;padding: 6px 12px;" size="mini" @click="addPartType()">新增</el-button>
                    </div>
                    <el-checkbox-group v-model="partCheckList" style="line-height:25px;padding-top: 10px;" @change="partCheckChange">
                        <el-checkbox :label="item.partName" v-for="item,index in partsList" :key="index">{{ item.partName }}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="partDialogShow = false" size="small">取 消</el-button>
                <el-button type="primary" @click="confirmPartSel" size="small">确定选择</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name:"PartsList",
    data(){
        return{
            tableData:[],
            gatherData:[{
                name:'小计：',
                partsTotal:0,//配件数汇总
                costTotal:0,//费用汇总
                checkCost:0,//核定金额
                reviewCost:0,//复核金额
            }],//汇总数据表格
            caseId:"",
            activeEleId:"",//目前操作的电器id
            activeCusId:"",//点击活跃的客户id
            eleDialogShow:false,//选择受损电器的弹框
            inputSpecsType:{},//规格及型号信息,
            eleSelList:{},//待选择的电器规格、型号
            eleSelObj:{},//已选择的电器规格、型号对象
            partDialogShow:false,//选择配件类型的弹框
            partsList:[],//电器对应的配件列表
            partCheckList:[],//已选中的配件列表
        }
    },
    watch:{
        tableData:{
            handler(newData){
                console.log(newData);
                const that  =this;
                let partsTotal =0;
                let costTotal = 0;
                newData.forEach(item=>{
                    // item.checkPrice = item.partsPrice + item.partsRepairCost;
                    // item.reviewPrice = item.checkPrice;
                    partsTotal = partsTotal + item.partsCount;
                    costTotal = costTotal + item.partsPrice + item.partsRepairCost;
                });
                that.gatherData[0].costTotal = costTotal;
                that.gatherData[0].checkCost = costTotal;
                that.gatherData[0].partsTotal = partsTotal;
            },
            deep:true
        }
    },
    created(){
        const that = this;
        if(that.$route.query.id){
            that.caseId = that.$route.query.id;
            that.initPage();
        }
    },
    methods:{
        costBlur(row){
            row.checkPrice = row.partsPrice+row.partsRepairCost;
        },
        countBlur(row){
            row.partsPrice = row.partsPrice * row.partsCount;
            row.partsRepairCost = row.partsRepairCost * row.partsCount;
            row.checkPrice = row.checkPrice*row.partsCount;
        },
        /**初始化页面的内容 */
        initPage(){
            const that =this;
            that.request.get("case/detail/init",{caseId:that.caseId}).then(res=>{
                if(res.code === 200){
                    console.log(res);
                    that.tableData = res.data;
                }
            });
        },
        /**选择受损电器，即电器类别下的受损电器 */
        selLoseEle(eleId,eleSpecsType){
            const that =this;
            that.request.get("ele/info/getEleSpecsType",{eleId:eleId}).then(res=>{
                if(res.code===200){
                    that.eleSelList = res.data;
                    that.eleDialogShow = true;
                    that.activeEleId= eleId;
                    console.log(eleSpecsType);
                    if(eleSpecsType){
                        let arr = eleSpecsType.split("-");
                        that.eleSelObj.eleSpecs = arr[0];
                        that.eleSelObj.eleType = arr[1];
                    }
                }
            });
        },
        /**选择电器相关的配件信息 */
        selParts(eleId,cusId,partsName){
            const that = this;
            that.request.get("parts/getPartByEleId",{eleId:eleId}).then(res=>{
                console.log(res);
                if(res.code===200){
                    that.partsList = res.data;
                    that.partDialogShow = true;
                    that.activeEleId = eleId;
                    that.activeCusId = cusId;
                    if(partsName){
                        that.partCheckList = partsName.split(",");
                    }
                }
            });
        },
        /**新增电器规格或者电器的型号 */
        addSpecsType(){
            const that  =this;
            let _params = {
                id : that.activeEleId,
                eleSpecs : that.inputSpecsType.eleSpecs,
                eleType:that.inputSpecsType.eleType
            }
            that.request.post("ele/info/saveEleSpecsType",_params).then(res=>{
                if(res.code===200){
                    that.eleSelList = res.data;
                    that.inputSpecsType={};
                }
            });
        },
        /** 新增配件类型*/
        addPartType(){
            const that = this;
            let _params={
                eleId:that.activeEleId,
                partName:that.inputSpecsType.partType
            }
            that.request.post("parts/savePart",_params).then(res=>{
                if(res.code===200){
                    that.partsList = res.data;
                    that.inputSpecsType = {};
                }
            });
        },
        /**确认电器规格及型号选择 */
        confirmEleSel(){
            const that = this;
            console.log(that.eleSelObj);
            that.tableData.forEach(item=>{
                if(item.eleId === that.activeEleId){
                    item.eleSpecsType=that.eleSelObj.eleSpecs+"-"+(that.eleSelObj.eleType?that.eleSelObj.eleType:'');
                }
            });
            that.eleDialogShow = false;
            that.eleSelObj = {};
            console.log(that.tableData);
        },
        /**配件类型选择事件触发 */
        partCheckChange(e){
            console.log(e)
        },
        /**确认配件类型的选择 */
        confirmPartSel(){
            const that  =this;
            var partsPrice = 0;
            var partsRepairCost = 0;
            that.partsList.forEach(item=>{
                that.partCheckList.forEach(item1=>{
                    if(item.partName==item1){
                        partsPrice += item.price;
                        partsRepairCost += item.repairCost;
                    }
                });
            });
            that.tableData.forEach(item=>{
                if(item.eleId === that.activeEleId&&item.cusId === that.activeCusId){
                    item.partsName = that.partCheckList.join(",");
                    item.partsPrice = partsPrice;
                    item.partsRepairCost = partsRepairCost;
                    item.partsCount = 1;
                    item.checkPrice = partsPrice+partsRepairCost;
                }
            });
            that.partDialogShow = false;
            that.partCheckList = [];
        },
        /**保存所有的详情信息至案件详细清单中。 */
        submitAll(){
            const that = this;
            that.request.get("case/getById",{id:that.caseId}).then(res=>{
                if(res.code===200){
                    let _status = res.data.status;
                    if(_status===5){
                        that.$message.error('领导已复核，不能再修改保存！');
                        return;
                    }else{
                        //暂时将reviewCost作为电器数量去存储。
                        that.gatherData[0].reviewCost = that.tableData.length;
                        let _params = {
                            totalObj:that.gatherData[0],
                            partsVoList:that.tableData
                        };
                        that.request.post("case/detail/saveCaseDetail",_params).then(res=>{
                            console.log(res);
                        });
                    }
                }
            });
        },
        /**生成并下载当前清单 */
        genWordDown(){
            const that = this;
            that.request.get("case/getById",{id:that.caseId}).then(res=>{
                if(res.code===200){
                    let _status = res.data.status;
                    if(_status!=5){
                        that.$message.error('领导复核之后才可以下载清单！');
                    }else{
                        axios({
                            url:"/api/case/detail/genWordDown",
                            params:{caseId:that.caseId},
                            headers: {'token': sessionStorage.getItem("token")},
                            responseType:"blob"
                        }).then(res=>{
                            let fileName = res.headers["content-disposition"].split(";")[1].split("filename=")[1];
                            if (res.status === 200) {
                                let url = window.URL.createObjectURL(new Blob([res.data], { type: "application/octet-stream" }))
                                let link = document.createElement('a')
                                link.style.display = 'none'
                                link.href = url
                                link.setAttribute('download', window.decodeURI(fileName))
                                document.body.appendChild(link)
                                link.click()
                                document.body.removeChild(link); // 下载完成移除元素
                                window.URL.revokeObjectURL(url); // 释放掉blob对象
                                that.downLoading = false;
                            }
                        }); 
                    }
                }
            });
        },
        /**合并单元格 */
        arraySpanMethod({ rowIndex,columnIndex}) {
            if(rowIndex===0){
                if(columnIndex ===0){
                    return [1,6]
                }
                if(columnIndex === 3){
                    return [1,2]
                }
            }
        },
        /**合并列 */
        rowMerge({row,rowIndex,columnIndex}){
            console.log(row,rowIndex,columnIndex);
            if (columnIndex === 1) {
                if (rowIndex % 2 === 0) {
                    return {
                    rowspan: 2,
                    colspan: 1
                    };
                } else {
                    return {
                    rowspan: 0,
                    colspan: 0
                    };
                }
            }
            }
    }
}
</script>
<style lang="less" scoped>
.page-container{

}
/deep/ .el-table td{
    padding: 5px 0px;
}
/deep/ .el-button--mini{
    padding: 0px;
}
/deep/ .el-input.is-disabled .el-input__inner{
    color: #3a3b3c;
}
</style>