<template>
    <div class="detail-page">
        <div class="detail-con">
            <div class="detail-case-info" v-if="caseinfo">
                <el-descriptions class="margin-top" title="案件基本信息" :column="3" :size="'medium'" border
                    :labelStyle="{ 'width': '215px' }">
                    <el-descriptions-item label="案件编号">{{ caseinfo.caseNum }}</el-descriptions-item>
                    <el-descriptions-item label="报案号">{{ caseinfo.reportCaseNum }}</el-descriptions-item>
                    <el-descriptions-item label="报案时间">{{ caseinfo.reportTime }}</el-descriptions-item>
                    <el-descriptions-item label="所属区域">{{ caseinfo.belongArea }}</el-descriptions-item>
                    <el-descriptions-item label="出险时间">{{ caseinfo.outDangerTime }}</el-descriptions-item>
                    <el-descriptions-item label="出险单位">{{ caseinfo.outDangerUnit }}</el-descriptions-item>
                    <el-descriptions-item label="出险地点">{{ caseinfo.outDangerAddr }}</el-descriptions-item>
                </el-descriptions>
                <div class="other-info">
                    <el-descriptions style="margin-top: 10px;" title="" :column="1" :size="'medium'" border
                        :labelStyle="{ 'width': '215px' }">
                        <el-descriptions-item label="事故原因">{{ caseinfo.caseDesc }}</el-descriptions-item>
                        <el-descriptions-item label="备注说明">{{ caseinfo.remark }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="事故原因">{{ caseinfo.accidentReason }}</el-descriptions-item> -->
                        <el-descriptions-item label="维修情况说明">{{ caseinfo.repairExplain }}</el-descriptions-item>
                        <el-descriptions-item label="维修人员信息">
                            <span v-for="item in repairPeoList" :key="item.id">{{ item.name + '(' + item.userTel + ')、' }}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="cost-info">
                    <el-descriptions style="margin-top: 10px;" title="" :column="4" :size="'medium'" border
                        :labelStyle="{ 'width': '215px' }">
                        <el-descriptions-item label="维修商报价">{{ caseinfo.totalCost }}元</el-descriptions-item>
                        <!-- <el-descriptions-item label="核定金额">{{caseinfo.checkCost}}元</el-descriptions-item>
                        <el-descriptions-item label="复核金额">{{caseinfo.reviewCost}}元</el-descriptions-item> -->
                        <el-descriptions-item label="客户总数">{{ cusList.length }}位</el-descriptions-item>
                        <el-descriptions-item
                            label="电器总数">{{ caseinfo.reviewCost ? caseinfo.reviewCost : 0 }}个</el-descriptions-item>
                        <el-descriptions-item
                            label="配件件数">{{ caseinfo.partsTotal ? caseinfo.partsTotal : 0 }}件</el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <!-- <div class="detail-repair-peo" v-if="repairPeoList&&repairPeoList.length!=0">
                <div style="padding-bottom:20px;font-weight:bold;">
                    <span>维修人员信息</span>
                </div>
                <div v-for="item in repairPeoList" :key="item.id">
                    <el-descriptions class="custom-des" title="维修员信息" :column="3" :size="'medium'">
                        <el-descriptions-item label="姓名">{{item.name}}</el-descriptions-item>
                        <el-descriptions-item label="联系方式">{{item.userTel}}</el-descriptions-item>
                        <el-descriptions-item label="证件号码">{{item.userUnit}}</el-descriptions-item>
                        <el-descriptions-item label="擅长领域" :column="1">{{item.userDesc}}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </div> -->
            <div class="detail-repair-peo" v-if="repairList.length != 0" style="margin-top: 10px;;">
                <div style="padding-bottom:20px;font-weight:bold;">
                    <span>案件维修情况</span>
                </div>
                <div v-for="item, index in repairList" :key="index">
                    <el-descriptions class="custom-des" :title="item.repairPeoName" :column="3" :size="'medium'" border
                        :labelStyle="{ 'width': '215px' }">
                        <el-descriptions-item label="开始维修时间">{{ item.repairTime }}</el-descriptions-item>
                        <el-descriptions-item label="维修完成时间">{{ item.repairFinishTime }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="事故原因">{{item.accidentReason}}</el-descriptions-item>
                        <el-descriptions-item label="维修情况说明">{{item.repairExplain}}</el-descriptions-item> -->
                    </el-descriptions>
                </div>
            </div>
            <div class="detail-cus-info" v-if="cusList && cusList.length != 0">
                <div style="padding-bottom:20px;font-weight:bold;">
                    <span>关联客户信息</span>
                </div>
                <el-table :data="cusList" border>
                    <el-table-column prop="cusName" label="客户姓名" align="center" width="100"></el-table-column>
                    <el-table-column prop="cusTel" label="联系方式" align="center" width="110"></el-table-column>
                    <el-table-column prop="idnum" label="证件号码" align="center" width="180"></el-table-column>
                    <el-table-column label="电器信息" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.eleList && scope.row.eleList.length != 0">
                                <el-tag v-for="item, index in scope.row.eleList" :key="index" :size="'mini'"
                                    style="margin-right: 10px;">{{ item.eleName }}</el-tag>
                            </div>
                            <div v-else>
                                <span>暂未上传电器信息</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="360">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showDialog = true; showObj = scope.row; showTag = 2;"
                                style="padding: 2px 5px;">查看维修资料</el-button>
                            <el-button type="text" @click="showDialog = true; showObj = scope.row; showTag = 1;"
                                style="padding: 2px 5px;">查看证件</el-button>
                            <el-button type="text"
                                @click="showDialog = true; showList = scope.row.eleList; showTag = 0; activeCusId = scope.row.id;"
                                style="padding: 2px 5px;">查看电器</el-button>
                            <el-button type="text" @click="delCusById(scope.row.id)"
                                style="padding: 2px 5px;">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="detail-approve-info" v-if="checkList.length != 0">
                <div class="title">核定记录</div>
                <el-timeline>
                    <el-timeline-item v-for="(activity, index) in checkList" :key="index" :timestamp="activity.approveTime"
                        placement="top">
                        <p>核定结果：{{ activity.remark }}</p>
                        <p>核定意见：{{ activity.opinion }}</p>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="detail-approve-info" v-if="reviewList.length != 0">
                <div class="title">复核记录</div>
                <el-timeline>
                    <el-timeline-item v-for="(activity, index) in reviewList" :key="index" :timestamp="activity.approveTime"
                        placement="top">
                        <p>复核结果：{{ activity.remark }}</p>
                        <p>复核意见：{{ activity.opinion }}</p>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
        <div class="ops-btn">
            <div v-if="$store.state.user.userType == '02' && src === 'check'">
                <div style="font-size: 16px;font-weight: 700;margin-bottom: 10px;">核定意见</div>
                <div class="ops-input-con">
                    <el-input type="textarea" :rows="3" placeholder="请输入核定意见" v-model="checkOpinion"></el-input>
                </div>
                <div class="btn">
                    <el-button type="primary" size="small" @click="checkCase('ok')">核定通过</el-button>
                    <el-button type="warning" size="small" @click="checkCase('no')">核定不通过</el-button>
                </div>
            </div>
            <div v-if="$store.state.user.userType == '01' && src === 'review'">
                <div style="font-size: 16px;font-weight: 700;margin-bottom: 10px;">复核意见</div>
                <div class="ops-input-con">
                    <el-input type="textarea" :rows="3" placeholder="请输入复核意见" v-model="reviewOpinion"></el-input>
                </div>
                <div class="btn">
                    <el-button type="primary" size="small" @click="reviewCase('ok')">复核通过</el-button>
                    <el-button type="warning" size="small" @click="reviewCase('no')">复核不通过</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="预览" :visible.sync="showDialog" custom-class="my-dialog-style" @close="dialogClose"
            @open="dialogOpen">
            <!-- 电器预览 -->
            <div v-if="showTag === 0">
                <div style="font-weight: bold;padding-bottom: 10px;">客户电器信息</div>
                <div v-if="showList.length != 0">
                    <el-tabs value="0" type="card">
                        <el-tab-pane :label="item.eleName" :name="index + ''" v-for="item, index in showList" :key="index">
                            <div v-for="item1, index1 in item.imgObj" :key="index1" class="ele-image-area">
                                <el-image :src="imgurl + item1.tempFilePath" :preview-src-list="[imgurl + item1.tempFilePath]"
                                    style="height:360px;margin-right: 20px;">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                </el-image>
                                <div class="image-btn" @click="delImage(item1.id)">
                                    <span class="el-icon-error" style="font-size: 24px;color: #bcbcbc;"></span>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div v-else style="display: flex;flex-direction: column;align-items: center;">
                    <el-image :src="require('@/assets/icon/noData.png')"></el-image>
                    <span>暂未上传电器信息</span>
                </div>
            </div>
            <!-- 证件预览 -->
            <div v-else-if="showTag === 1">
                <div style="font-weight: bold;padding-bottom: 10px;">客户证件信息</div>
                <div v-if="showObj.idImgFront && imgurl + showObj.idImgBack">
                    <el-image :src="imgurl + showObj.idImgFront"
                        style="width:420px;height:240px;margin-right: 20px;cursor: zoom-in;"
                        :preview-src-list="[imgurl + showObj.idImgFront]"></el-image>
                    <el-image :src="imgurl + showObj.idImgBack" style="width:420px;height:240px;cursor: zoom-in;"
                        :preview-src-list="[imgurl + showObj.idImgFront]"></el-image>
                </div>
                <div v-else style="display: flex;flex-direction: column;align-items: center;">
                    <el-image :src="require('@/assets/icon/noData.png')"></el-image>
                    <span>暂未上传客户证件信息</span>
                </div>
            </div>
            <!-- 维修资料预览 -->
            <div v-else-if="showTag === 2">
                <div style="font-weight: bold;padding-bottom: 10px;">维修资料预览</div>
                <div v-if="otherImgList.length != 0">
                    <div v-for="item1, index1 in otherImgList" :key="index1" class="ele-image-area">
                        <el-image :src="imgurl + item1.imgPath" :preview-src-list="[imgurl + item1.imgPath]"
                            style="height:360px;margin-right: 20px;">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                        <div class="image-btn" @click="delOtherImage(item1.id)">
                            <span class="el-icon-error" style="font-size: 24px;color: #bcbcbc;"></span>
                        </div>
                    </div>
                </div>
                <div v-else style="display: flex;flex-direction: column;align-items: center;">
                    <el-image :src="require('@/assets/icon/noData.png')"></el-image>
                    <span>暂未上传维修资料</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "Detail",
    data() {
        return {
            id: "",
            caseinfo: null,//案件基本信息
            cusList: null,//客户基本信息
            repairPeoList: [],//维修工信息...？需关联获取相关信息
            repairList: [],//维修情况说明
            checkList: [],
            reviewList: [],
            src: "",//操作来源：detail、check、review
            checkOpinion: "",
            reviewOpinion: "",
            showDialog: false,//图片预览界面
            showTag: 0,//0：电器预览；1：证件预览
            activeCusId: "",
            showList: [],//待预览的电器数据列表
            showObj: null,//带预览的客户证件对象
            otherImgList: [],//维修资料预览
        }
    },
    created() {
        const that = this;
        that.src = that.$route.query.flag;
        if (that.$route.query.id) {
            that.id = that.$route.query.id;
            that.initPage();
        }
    },
    methods: {
        /**初始化页面 */
        initPage() {
            const that = this;
            that.request.get("case/detail", { id: that.id }).then(res => {
                console.log(res);
                if (res.code === 200) {
                    that.caseinfo = res.data.caseInfo;
                    that.cusList = res.data.cusList;
                    that.repairPeoList = res.data.repairPeoList;
                    that.repairList = res.data.repairList;
                    that.checkList = res.data.checkList;
                    that.reviewList = res.data.reviewList;

                    /**根据当前是否激活的客户id，判断是否需要重新初始化图片展示列表 */
                    if (that.activeCusId) {
                        that.getCusObj();
                    }
                }
            });
        },
        /**客户列表中查询当前激活的用户 */
        getCusObj() {
            const that = this;
            let nowCusObj = that.cusList.find(e => e.id === that.activeCusId);
            that.showList = nowCusObj.eleList;
        },
        /**返回上一页 */
        backPre() {
            this.$router.go(-1)
        },
        /**核定操作 */
        checkCase(e) {
            const that = this;
            let params = {
                caseId: that.id,
                checkOpinion: that.checkOpinion,
                isPass: e
            }
            that.request.post("case/check", params).then(res => {
                if (res.code === 200) {
                    this.$router.push("/case/index");
                }
            });
        },
        /**复核操作*/
        reviewCase(e) {
            const that = this;
            let params = {
                caseId: that.id,
                reviewOpinion: that.reviewOpinion,
                isPass: e
            }
            that.request.post("case/review", params).then(res => {
                if (res.code === 200) {
                    this.$router.push("/case/index");
                }
            });
        },
        /**删除维修资料的图片信息 */
        delOtherImage(id){
            const that = this;
            that.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
                confirmButtonText: '确定删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("/wxapi/custom/delOtherImg", { imgId: id }).then(res => {
                    if(res.code===200){
                        that.dialogOpen();
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**删除已上传的图片信息 */
        delImage(id) {
            const that = this;
            that.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
                confirmButtonText: '确定删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log(id);
                that.request.get("/new/case/delEleImg", { imgId: id }).then(res => {
                    if (res.code === 200) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.initPage();
                    }
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**删除客户数据 */
        delCusById(cusId) {
            const that = this;
            that.$confirm('此操作将永久删除该客户及关联的相关数据, 是否继续?', '提示', {
                confirmButtonText: '确定删除',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("/cusinfo/delete", { cusId: cusId }).then(res => {
                    if (res.code === 200) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.initPage();
                    }
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**客户证件电器预览 */
        seeCard(e) {
            console.log(e);
            // let _showObj = {
            //     eleName:"正面",

            // }
        },
        /**图片预览窗口关闭事件 */
        dialogClose() {

        },
        /**图片预览窗口打开事件 */
        dialogOpen() {
            const that = this;
            if (that.showTag === 2) {
                const caseId = that.id;
                const cusId = that.showObj.id;
                that.request.get("/cusinfo/listOtherImg", { cusId: cusId, caseId: caseId }).then(res => {
                    if (res.code === 200) {
                        that.otherImgList = res.data;
                    }
                });
            }
        }
    }
}
</script>
<style lang="less" scoped>
.detail-page {
    .ele-img {
        display: flex;
        flex-direction: row;
        padding: 10px 0px;
    }

    .ops-btn {
        .btn {
            margin-top: 10px;
        }
    }

    .detail-approve-info {
        margin-top: 20px;

        .title {
            padding: 10px 0px 20px;
            font-weight: bold;
        }
    }

    .ele-image-area {
        display: inline-block;
        position: relative;

        .image-btn {
            position: absolute;
            top: 10px;
            right: 30px;
            cursor: pointer;
        }
    }

    /deep/ .custom-des {
        margin-left: 20px;

        .el-descriptions__header {
            margin-bottom: 5px;
        }
    }
}</style>