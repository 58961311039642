import { render, staticRenderFns } from "./partslist.vue?vue&type=template&id=b37ada20&scoped=true&tips=%E9%85%8D%E4%BB%B6%E5%88%97%E8%A1%A8%E4%BF%A1%E6%81%AF&"
import script from "./partslist.vue?vue&type=script&lang=js&"
export * from "./partslist.vue?vue&type=script&lang=js&"
import style0 from "./partslist.vue?vue&type=style&index=0&id=b37ada20&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b37ada20",
  null
  
)

export default component.exports